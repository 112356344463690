import female from "./female.jpg";
import male from "./male.jpg";
import backgroud from "./background.jpeg";
import logo from "./logo.png";

export {
    female,
    male,
    backgroud,
    logo
};