import posterImageEpisode1 from "./episode-1.jpg";
import posterImageEpisode2 from "./episode-2.jpg";
import posterImageEpisode3 from "./episode-3.jpg";
import posterImageEpisode4 from "./episode-4.jpg";
import posterImageEpisode5 from "./episode-5.jpg";
import posterImageEpisode6 from "./episode-6.jpg";


export {
    posterImageEpisode1,
    posterImageEpisode2,
    posterImageEpisode3,
    posterImageEpisode4,
    posterImageEpisode5,
    posterImageEpisode6
};